export const themeOld = {
  primaryFontFamily: "Crimson Text, sans-serif",
  primaryFontSize: "16px",
  logoFontFamily: "Silkscreen, sans-serif",
  primary800: "#252626",
  primary700: "#3e3f40",
  primary600: "#969799",
  primary300: "#e6e7e8",
  primary200: "#f5f6f7",
  primary100: "#ffffff",
  buttonFontSize: "16px",
  buttonLineHeight: "24px",
  buttonPadding: "10px 12px",
  buttonBorderRadius: "10px",
  buttonFontWeight: "600",
};

export const theme = {
  font: {
    family: "Open Sans, sans-serif",
    size: "16px",
    lineHeight: "24px",
  },
  color: {
    grey800: "#252626",
    grey700: "#3e3f40",
    grey600: "#707173",
    grey500: "#B2B6BF",
    grey300: "#E6E7E8",
    grey200: "#F5F6F7",
    grey100: "#FAFBFC",
    red800: "#FF6767",
    red700: "#FF8080",
    red600: "#FF9999",
    red300: "#FFE0E0",
    red200: "#FFF0F0",
    red100: "#FFFAFA",
  },
  button: {
    fontSize: "14px",
    lineHeight: "22px",
    padding: "10px 12px",
    borderRadius: "10px",
    fontWeight: "500",
  },
};
